<template>
    <div>
        <!-- all page's children will inhert parents layout -->
        <div class="flex h-full w-full pb-4 lg:pt-0">
            <!-- <div class="hidden w-48 grow-0 bg-white px-0 pr-2 pt-9 lg:block">
                <div>navbar was here</div>
            </div> -->
            <div class="flex w-full flex-col lg:px-8 lg:pt-7">
                <div class="w-full lg:pb-16">
                    <NuxtPage />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup></script>

<style scoped></style>
